import '../App.css';
import '../web-fonts/font.css';
import sayang_message2 from '../assets/sayang_message2.png';

function P3() {
  return (
    <div className="App">
      <div className="packages">
        <div className="p1">
          <div className="p1-text">
            <p>
              {'> '}
              <b>CONNECTED.</b>
            </p>{' '}
            <p>{'> '}Island integrity: 96%</p> <p>{'> '}Welcome home.</p>{' '}
          </div>
        </div>
        <img
          id="sayang-message2"
          src={sayang_message2}
          width={'100%'}
          draggable={false}
          alt=""
        />
        <div className="p1">
          <div className="p1-text">
            <p>{'> '} Message saved.</p>
            <p>{'> '} Closing connection…</p>
            <p>
              {'> '} <b>OFFLINE.</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default P3;
