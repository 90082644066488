import '../App.css';
import '../web-fonts/font.css';
import exclaim_tiny from '../assets/exclaim-tiny.gif';

function Credits() {
  return (
    <div className="App">
      <div className="packages">
        <div className="bios">
          <div className="about-header">
            <div>ABOUT US!</div>
            <img src={exclaim_tiny} alt="exclamation mark" />
          </div>

          <div className="shared-bio">
            Isabel and Sidnee are multimedia artists and third culture kids from
            [China, New Zealand] and [Singapore, Malaysia], respectively. They
            share a love for stories that bridge queer identity, digital
            intimacy, and cultural distance. They both enjoy 17776 and
            unorthodox keyboard emoticons. Sidnee and Isabel currently reside on
            opposite coasts of the United States, and have yet to meet in real
            life.
          </div>
          <div className="i-bio">
            <b>Isabel Li</b> is a Chinese-Kiwi painter, writer, and researcher
            based in San Francisco. They love telling stories about distance,
            queerness, technological futures, intimacy, and care. Recently they
            have been following frumpy cats through bookstore aisles, against
            Chinatown's paved inclines, and eating fruit on the floor.
          </div>
          <div className="s-bio">
            <b>Sidnee Lim</b> is a Singaporean-Malaysian filmmaker based in
            Brooklyn. They are currently doing their masters in Film and Media
            Studies at Columbia University, and spends their time across
            creative projects responding to the distinct experience of
            unbelonging minorities silently endure, and the community and love
            that can be found after.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Credits;
