import '../App.css';
import '../web-fonts/font.css';
import deyu_message2 from '../assets/deyu_message2.png';

function P3() {
  return (
    <div className="App">
      <div className="packages">
        <img
          id="deyu-message2"
          src={deyu_message2}
          width={'100%'}
          draggable={false}
          alt=""
        />
      </div>
    </div>
  );
}

export default P3;
