import { useEffect, pop } from 'react';
import '../App.css';
import '../web-fonts/font.css';
import chat1 from '../assets/short_chats/1.png';
import chat1_2 from '../assets/short_chats/1_2.png';
import chat2 from '../assets/short_chats/2.png';
import chat3 from '../assets/short_chats/3.png';
import chat3_2 from '../assets/short_chats/3_2.png';
import chat4 from '../assets/short_chats/4.png';
import chat4_2 from '../assets/short_chats/4_2.png';
import frog from '../assets/frog.png';
import frogdew from '../assets/frogdew.png';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.compat.css';

function P5() {
  //   useEffect(() => {
  //     window.addEventListener('scroll', pop);

  //     return () => window.removeEventListener('scroll', pop);
  //   }, []);
  //   let ourConcert = new Audio('/christmas.mp3');

  //   const start = () => {
  //     ourConcert.play();
  //   };

  //   const pop = () => {
  //     if (window.scrollY > 1200) {
  //       start();
  //     }
  //   };

  return (
    <div className="App">
      <div className="packages" id="short-chats">
        <img id="chat1" src={chat1} width={'100%'} draggable={false} alt="" />
        <img
          id="chat1_2"
          src={chat1_2}
          width={'100%'}
          draggable={false}
          alt=""
        />
        <img id="chat2" src={chat2} width={'100%'} draggable={false} alt="" />
        <img id="chat3" src={chat3} width={'100%'} draggable={false} alt="" />
        <img
          id="chat3_2"
          src={chat3_2}
          width={'100%'}
          draggable={false}
          alt=""
        />
        <div style={{ position: 'relative' }}>
          <img id="chat4" src={chat4} width={'100%'} draggable={false} alt="" />
          {/* 
          <ScrollAnimation offset={0} duration={0.6} animateIn="fadeIn"> */}
          <div id="frog-div">
            {' '}
            <img id="frog" src={frog} draggable={true} alt="" />
            <div id="frogdew-div">
              <img id="frogdew" src={frogdew} draggable={true} alt="" />
            </div>
          </div>
          {/* </ScrollAnimation> */}
        </div>

        <img
          id="chat4_2"
          src={chat4_2}
          width={'100%'}
          draggable={false}
          alt=""
        />

        <div className="tbc">Receiving next transmission...</div>
      </div>
    </div>
  );
}

export default P5;
